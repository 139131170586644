import ls from '@livesession/sdk';

const LivesessionID = process.env.REACT_APP_LIVESESSION_ID;
const isDebugLivesession = process.env.REACT_APP_DEBUG_LIVESESSION;

export const uxTracking = {
  init: () => {
    try {
      if (isDebugLivesession) {
        console.log('[uxTracking:init]');
      }
      if (LivesessionID) {
        ls.init(LivesessionID, { keystrokes: true });
        ls.newPageView();
      }
    } catch (error) {
      // do nothing
    }
  },
  identify: (id, data) => {
    try {
      if (isDebugLivesession) {
        console.log('[uxTracking:identify]', id, data);
      }
      if (LivesessionID) {
        const params = { ...data };
        delete params.name;
        delete params.email;
        ls.identify({
          name: data.name,
          email: data.email,
          params,
        });
      }
    } catch (error) {
      // do nothing
    }
  },
  anonymize: () => {
    try {
      if (isDebugLivesession) {
        console.log('[uxTracking:anonymize]');
      }
      if (LivesessionID) ls.invalidateSession();
    } catch (error) {
      // do nothing
    }
  },
};
