
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import Rating from '../Rating/Rating';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Buttons/Button';
import Content from '../../UI/Content/Content';
import { delay } from '../../../util/ExtraMethods';
import { uxTracking } from '../../../util/ux-tracking';
import TextContainer from '../../UI/Content/TextContainer';
import { EmailPattern, reviewPath } from '../../../util/lib';
import css from './ReviewForm.module.css';

const ReviewForm = (props) => {
  const { api, legacy, review, defaultTitle } = props;
  const badTitle = defaultTitle;
  const [title, setTitle] = useState(defaultTitle);
  const [rating, setRating] = useState();
  const [loading, setLoading] = useState();
  const {code} = useParams();
  const history = useHistory();
  const form = useForm({ mode: 'onChange' });
  const { formState } = form;

  useEffect(() => {
    const checkError = async () => {
      const path = reviewPath(code, null, legacy);
      if (!review) history.push(path);
    };
    checkError();
  }, [code, review, history, legacy]);

  const disableSubmit = loading || !rating || !formState.isValid;
  const onSubmit = form.handleSubmit(async (formData) => {
    try {
      if (disableSubmit) return;
      setLoading(true);
      await delay(1000);
      const data = {
        ...formData,
        rating,
        channel: 'internal',
        reviewedAt: new Date(),
        partnerId: review.partnerId,
      };
      const response = await api({
        data,
        method: 'put',
        responseType: 'json',
        url: `/reviews/${review._id}`,
      });
      if (response.data && response.data.review) {
        uxTracking.identify(review.code, {
          phone: review.phone,
          email: review.email,
          rating: review.rating,
          feedback: review.feedback,
          firstName: review.firstName,
        });
      }
      setLoading(false);
      if (props.onReviewed) {
        props.onReviewed(data);
        return;
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  });
  const onChangeRating = (rating) => {
    if (rating < 2) setTitle(badTitle);
    else setTitle(defaultTitle);
    setRating(rating);
  };
  return (
    <Content>
      <TextContainer>{title}</TextContainer>
      <Rating onChange={onChangeRating} />
      {review && review.generic && (
        <>
          <Input
            form={form}
            name='firstName'
            placeholder='Name'
            className={css.InputText}
            rule={{
              required: 'Please tell us your name.',
              maxLength: {
                value: 50,
                message: 'Are you sure your name is correct?',
              },
              minLength: {
                value: 3,
                message: 'Are you sure your name is correct?',
              },
            }}
          />
          <Input
            form={form}
            name='email'
            placeholder='Email'
            className={css.InputText}
            rule={{
              maxLength: {
                value: 100,
                message: 'Are you sure Email is correct?',
              },
              minLength: {
                value: 3,
                message: 'Are you sure Email is correct?',
              },
              pattern: {
                value: EmailPattern,
                message: 'Are you sure Email is correct?',
              },
            }}
          />
        </>
      )}
      <Input
        rows={3}
        form={form}
        name='feedback'
        type='textarea'
        inputClassName={css.TextArea}
        placeholder='Describe your experience with us here ...'
        rule={{
          maxLength: {
            value: 500,
            message: 'Please describe your experience in shorter version',
          },
          minLength: {
            value: 1,
            message: 'Please describe your experience first',
          },
        }} />
      <Button
        type='main'
        onClick={onSubmit}
        disabled={disableSubmit}
        className={css.SubmitButton}>
        {loading ? 'Sending feedback..' : 'Submit'}
      </Button>
    </Content>
  );
};
export default ReviewForm;
